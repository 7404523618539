import { template as template_9e42929d28b449f2a82d2bee0883bf8b } from "@ember/template-compiler";
const SidebarSectionMessage = template_9e42929d28b449f2a82d2bee0883bf8b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
