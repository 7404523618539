import { template as template_88429a24d1c04153a178d4b8c068d683 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import DToggleSwitch from "discourse/components/d-toggle-switch";
export default class FKControlToggle extends Component {
    static controlType = "toggle";
    @action
    handleInput() {
        this.args.field.set(!this.args.field.value);
    }
    static{
        template_88429a24d1c04153a178d4b8c068d683(`
    <DToggleSwitch
      @state={{@field.value}}
      disabled={{@field.disabled}}
      {{on "click" this.handleInput}}
      class="form-kit__control-toggle"
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
