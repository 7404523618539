import { template as template_a9592e98c6244b478910e1c4691507be } from "@ember/template-compiler";
const FKLabel = template_a9592e98c6244b478910e1c4691507be(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
