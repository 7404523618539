import { template as template_1ee0b3052d3844039c3bf224e11ef315 } from "@ember/template-compiler";
const FKText = template_1ee0b3052d3844039c3bf224e11ef315(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
