import { template as template_2ae95d996b2c46e0b9a3f323e4e5651d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_2ae95d996b2c46e0b9a3f323e4e5651d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
